.yper--sav_wrapper{
  a{
    color: $color_highlight_400;
  }
  position:relative;
  height: 100%;
  width: 100%;
  padding: 70px 0;
  margin:0 auto 70px auto;
  .yper--sav_content{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width:1240px;
    width: 90%;
    margin: 0 auto 0 auto;
  }
  .yper--sav_img{
    position: relative;
    width: 100%;
    height: auto;
    img{
      width: 100%;
      height: 300px;
      object-fit: contain;
    }

  }
  .yper--sav-text_wrapper{
    h2{
      font-size: 1.5rem;
      color: $color_secondary_400;
      font-weight: bold;
      line-height: 150%;
    }
    .yper--sav_text{
      margin: 30px 0;
      h3{
        display: flex;
        align-items: center;
        margin: 10px 0;
        font-size: 1.25rem;
        color: $color_secondary_400;
        .yellow--deco{
          font-size: 3rem;
          font-weight: bold;
          top: -3px;
          position: relative;
        }
      }
    }
  }
}
.deco {
  display: none;
  position: absolute;
}
@media screen and (min-width:620px){
  .yper--sav_wrapper {
    .yper--sav_content {
      flex-direction: row;
      justify-content: space-between;
      h2{
        text-align: left;
        justify-content: flex-start;
        font-size: 2.37rem;
        font-weight: 500;
      }
    }
    .yper--sav_img, .yper--sav-text_wrapper{
      width: 45%;
    }
    .yper--sav-text_wrapper{
      h3{
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (min-width:992px){
  .yper--sav_wrapper .yper--sav_img{
    img{
      height: auto;
    }
    .deco{
      display: block;
    }
    .deco--img{
      width: auto;
      height: auto;
      right: 33px;
      top: -63px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .yper--sav_wrapper {
    .deco--left {
      bottom: 0;
      left: 0;
    }

    .deco--right {
      top: 20%;
      right: -33px;
    }
  }
}