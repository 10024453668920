.deliverer--explanation_main{
  width: 90%;
  max-width: 1240px;
  margin: 60px auto;
  .deliverer--explanation_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
      font-size: 1.5rem;
      line-height:150%;
      color: $color_secondary_400;
    }
    .deliverer--explanation_bloc{
      margin: 20px 0;
    }
    .deco--yellow{
      color:$color_primary_400;
      font-size: 3rem;
      top:-3px;
    }
    .title--bloc{
      display: flex;
      flex-direction: row;
      align-items: center;
      h4{
        display: flex;
        align-items: center;
        font-size: 1.24rem;
        color: $color_secondary_400;
      }
    }
  }
  .img--content{
    width: 100%;
    height: auto;
    img{
      height: 354px;
      width: auto;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 620px){
  .deliverer--explanation_main {
    .deliverer--explanation_content {
      flex-direction: row-reverse;
      justify-content: space-between;
      h3{
        font-size: 2.37rem;
      }
      .text--content,.img--content{
        width: 50%;
      }
      .title--bloc h4{
        h4{
          font-size: 1.5rem;
        }
      }
      .img--content{
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
          height: auto;
          width: 80%;
          object-fit: cover;
        }
      }
    }
  }
}