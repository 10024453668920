.presentation--products_main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position:relative;
  width: 90%;
  max-width: 1240px;
  margin: 80px auto;
  .bloc--img_wrapper{
    position: relative;
    width: 100%;
    height: auto;
    img{
      width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }
  .notation{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .stars--wrapper{
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items:center;
      i, img{
        opacity: 0;
        transform:translateY(-100);
      }
      i{
        color:$color_primary_400;
        font-size: 29px;
      }
    }
    a.btn--main{
      margin-top:30px;
    }
  }
  h3{
    font-family:"manrope",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 150%;
    margin:20px 0;
    color: $color_secondary_400;
  }
  p{
    margin:20px 0;
  }
}
@media screen and (min-width: 620px){
  .presentation--products_main{
    flex-direction:row;
    justify-content: space-between;
    .bloc--text_wrapper, .bloc--img_wrapper{
      width:45%;
    }
    .bloc--img_wrapper{
      img{
        height: auto;
      }
      .deco--img{
        width: auto;
        height: auto;
      }
    }
    h3{
      font-size: 2.37rem;
    }
    a.btn--main{
      display: inline-flex;
    }
  }
}