.yper--advantages_wrapper{
  width:90%;
  max-width: 1240px;
  margin: 70px auto;
  h2{
    margin: 2px auto;
    line-height: 150%;
    font-size: 2.37rem;
  }
}
.yper--advantages_content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .img--advantages{
    width: 100%;
    text-align: center;
    height: 100%;
    overflow:hidden;
    img{
      width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }
}
.text--advantages_content{
  .text--advantages{
    margin:60px 0;
    h3{
      img{
        margin:10px 0;
      }
      display: flex;
      margin:30px 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.5rem;
      color: $color_secondary_400;
    }
  }
}

@media screen and (min-width: 660px) {
  .yper--advantages_content{
    flex-direction: row;
    justify-content: space-between;
  }

  .text--advantages_content{
    .text--advantages{
      h3{
        align-items:center;
        img{
          margin:0;
        }
        margin:30px 0;
        flex-direction: row;
      }
    }
  }
}

@media screen and (min-width: 990px) {
  .yper--advantages_content{
    flex-direction: row;
    justify-content: space-between;
    .img--advantages{
      width: 50%;
      img{
        height: auto;
        width: 408px;
      }
    }
    .text--advantages_content {
        width: 45%;
    }
  }
}