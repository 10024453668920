.our--job_text{
  width: 90%;
  margin: 60px auto;
  max-width: 1240px;
  .wrapper--items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item--atout_img{
      display: flex;
      justify-content: center;
      width: auto;
      img {
        height: 300px;
        margin-bottom: 38px;
      }
    }
    .img--svg img{
        height: auto;
        width: 80%;
        margin-bottom:0;
      }
    .item--atout {
      margin: 30px 0;
      p.title--item {
        font-family: "manrope", sans-serif;
        font-weight: 700;
        font-size: 1.15rem;
        margin-bottom: 10px;
      }
    }
    .wrapper--items_content{
      margin:30px 0;
    }
  }
}

@media screen and (min-width: 620px) {
  .our--job_text {
    width: 90%;
    margin: 60px auto;
    max-width: 1240px;
    .wrapper--items_content {
      height: 320px;
      margin: 64px auto;
      .item--atout {
        p.title--item {
          font-size: 1.5rem;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .img--svg img{
        height: auto;
        margin--bottom:0;
      }
      .item--atout_img {
        width: 45%;
        img {
          height: 300px;
          margin-bottom: 38px;
        }
      }
      .items--atout_content{
        width: 45%;
        align-self: center;
      }
    }
    .illu--api{
      height:100%;
      img{
        height: auto;
      }
    }
    .wrapper--items_content{
      img{
      margin-bottom:0;
      }
    }
    .wrapper--items_bottom{
      flex-direction: row-reverse;
      .item--atout_img{
        img{
          width: 90%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .our--job_text {
    .wrapper--items {
      .item--atout_img {
        img {
          height: 500px;
        }
      }
    }
    .illu--api{
      height:100%;
      .item--atout_img img{
        height: auto;
      }
    }
  }
}
