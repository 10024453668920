.help--main_wrapper{
  position:relative;
  h3{
    color:$color_secondary_400;
    max-width:1240px;
    margin:20px auto;
    width:90%;
    font-weight: 400;
    font-size: 1.5rem;
  }
  padding:30px 0;
  margin:80px 0;
  background-color: rgba($rider--theme_400,0.1);
  .main--help_card{
    width: 90%;
    max-width: 1240px;
    margin:0 auto;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  a.help--subtitle{
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    min-height:101px;
    width: 100%;
    padding:20px;
    h4{
      display: flex;
      font-weight:normal;
      margin:10px 0;
      flex-direction: row;
      color:$color_secondary_400;
      font-size: 1.5rem;
      img{
        width:40px;
      }
    }
    p{
      align-items:center;
      color:$color_highlight_400;
      display: flex;
      flex-direction: row;
    }
    margin:20px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(54, 80, 108, 0.05);
    border-radius: 16px;
  }
}

@media screen and (min-width:620px){
  .help--main_wrapper{
    h3{
      font-weight: 400;
      font-size: 2.375rem;
    }
    .main--help_card{
      flex-direction:row;
      justify-content:space-between;
      .help--subtitle{
        width:32%;
        min-height: 192px;
      }
    }
  }
}

@media screen and (min-width:1190px){
  .help--main_wrapper{
    .main--help_card{
      .help--subtitle{
        min-height: initial;
      }
    }
  }
}
