@import '../../../../../../node_modules/slick-carousel/slick/slick';
.we--recommand_main {
  position: relative;
  padding: 40px 0;
  background-color: $color_secondary_50;
  .we--recommand_wrapper{
    height: 100%;
    padding: 26px 0;
    margin: 40px 0;
  }
  .slider--title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:90%;
    max-width: 1240px;
    margin:0 auto;
    h2 {
      padding: 20px 0;
      width: 100%;
      justify-content: flex-start;
    }
    .slider--arrow{
      bottom:5%;
    }
  }
  .slick-list {
    padding: 0 30% 0 0 !important;
  }
  .slick-slide{
      margin: 0 0 0 8px !important;
  }
  .we--recommand{
    width: 100%;
    margin-left: 5%;
    position: relative;
    .we--recommand_wrapper{
      padding: 130px 0;
    }
    .we--recommand_card{
      background: white;
      height: 260px;
      box-shadow: 0 4px 10px rgba(143, 149, 158, 0.1);
      border-radius: 5px;
      .we--recommand_img{
        width:90%;
        height:140px;
        margin: 0 auto;
        overflow: hidden;
        padding: 10px 0;
        img{
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }
      .we--recommand_text{
        width: 90%;
        margin:0 auto;
        h3{
          font-size: 1rem;
          color:$color_secondary_400;
          text-align: center;
          margin: 15px 0 15px;
        }
        p{
          display: none;
        }
      }
      .we--recommand_know {
          display:flex;
          justify-content:center;
        a {
          color: $rider--theme_400;
          display: flex;
          font-family: "manrope",sans-serif;
          font-weight: 700;
          font-size: 0.85rem;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (min-width:620px){
  .we--recommand_main .we--recommand .we--recommand_card .we--recommand_know a{
    font-size:1rem;
  }
}
@media screen and (min-width:920px) {
  .we--recommand_main {
    .slider--title h2{
      width:60%;
    }
    .we--recommand {
      margin: 0 8% 0;

      .we--recommand_card {
        height: 423px;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .we--recommand_img {
          height: 190px;
        }
        .we--recommand_text {
          h3 {
            margin-bottom: 30px;
            text-align: left;
            font-size: 1.25rem;
            text-align: left;
          }

          p {
            text-align: left;
            display: flex;
          }
        }
        .we--recommand_know {
          margin-top: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (min-width:1300px) {
  .we--recommand_main {
    .we--recommand {
      margin: 0 12% 0;
    }
  }
}