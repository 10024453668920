.wrapper--slider.wrapper--slider_desktop{
   display: none;
 }
.wrapper--slider.wrapper--slider_responsive{
  display: flex;
  //margin-top: 50px;
  .block--text--content{
    .block--text{
      display: flex;
      flex-direction: column;
      .bloc--img {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color_secondary_400;
        height: 300px;
        img{
          width: 100%;
          height: 272px;
          object-fit: contain;
        }
      }
      .text--content{
        width: 80%;
        margin: 60px auto;
      }
    }
  }
}
.wrapper--slider{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.bloc--left{
  background: $color_secondary_400;
  position: relative;
  height: 400px;
  align-items: center;
  justify-content: center;
}
.text--content{
  width: 80%;
  margin: 0 auto;
  h6{
    font-family: "Manrope",sans-serif;
    font-weight: 600;
    font-size: 0.85rem;
    display: flex;
    color: $color_secondary_400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  h3{
    margin: 20px 0;
    color: $color_secondary_400;
    line-height: 150%;
    font-size:1.5rem;
    font-weight: 400;
    }
  p{
    margin: 10px 0;
  }
  .advantages--text{
    margin-top: 50px;
    p{
      display: flex;
      align-items: center;
      margin: 15px 0;
      i{
        font-size: 2rem;
        color: $color_primary_400;
      }
    }
  }
  a.redirection--btn{
    margin:50px 0;
    padding: 18px 8px;
    width: 100%;
  }
}

@media screen and (min-width: 620px){
  .text--content a.redirection--btn{
    display: inline-flex;
    padding: 18px;
    width: auto;
  }
  .text--content {
    width: 60%;
    h3 {
      font-size: 2.37rem;
    }
  }
}
@media screen and (min-width: 992px){
  .wrapper--slider.wrapper--slider_responsive{
    display:none;
  }
  .wrapper--slider.wrapper--slider_desktop{
    display:flex;
  }
  .wrapper--slider{
    flex-direction: row;
    justify-content: space-between;
    overflow:hidden;
    top:0!important;
  }
  .bloc--left, .block--text--content{
    width: 50%;
  }
  .bloc--left{
    background: $color_secondary_400;
    position: relative;
    bottom:0;
    display: flex;
    height: calc(100vh + 75px)!important;
    align-items: center;
    justify-content: center;
  }
  .block--text--content{
    margin-left: auto;
  }
  .block--text{
    background: white;
    display: flex;
    height: 100vh;
    position:relative;
    align-items: center;
    justify-content: center;
  }
  .end--of_sliders{
    height: 100px;
    width:50%;
    z-index: 0;
    background:$color_secondary_400;
    display: block;
    position:relative;
  }

  .trajectory--deco{
    height: 100%;
    width: 100%;
  }
  .mockup{
    position:absolute;
    opacity: 0;
    width: 500px;
    transform: translateY(-100px);
    height: auto;
    z-index: 2;
  }
  .mockup--mac{
    position:absolute;
    opacity: 0;
    width: 500px;
    transform: translateY(-100px);
    height: auto;
    z-index: 2;
  }
  img.macbook--empty{
    position: absolute;
    z-index: 3;
    width: auto;
    height: 272px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .default--mockup_empty{
    position: absolute;
    z-index: 3;
    width: auto;
    height: 265px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .rectangle--video_macbook{
    width: 383px;
    background: #36506c;
    height: 99%;
    left: 7px;
    overflow: hidden;
    z-index: -2;
    position: relative;
    margin: 0 auto;
    top: 8px;
  }
  video{
    width: 100%;
    height: auto;
    position: relative;
  }

  .wrapper--mockup{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    left: 0;
    right: 0;
    width: 71vh;
    position: relative;
    overflow: hidden;
  }

  img.phone--empty{
    position: absolute;
    z-index: 3;
    width: auto;
    height: 568px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  .rectangle--video_phone{
    width: 253px;
    background: #36506c;
    height: 98%;
    top: 4px;
    overflow: hidden;
    z-index: -2;
    position: relative;
    margin: 0 auto;
  }
    .mockup--phone{
      height: 550px;
  }
  video{
    width: 100%;
    height: auto;
    position: relative;
  }
  .wrapper--mockup_phone{
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    bottom:0;
    height: 100%;
    left: 0;
    right: 0;
    width: 71vh;
    position: relative;
    overflow: hidden;
  }
  #path{
    position:absolute;
  }
}


@media screen and (min-width:1450px){
  .mockup--mac{
    width: 500px;
  }
  img.macbook--empty{
    height: 384px;
  }
  .default--mockup_empty{
    height: 371px;
  }
  .rectangle--video_macbook{
    width: 530px;
    background: #36506c;
    height: 99%;
    left: 7px;
    top: 14px;
  }

  img.phone--empty{
    position: absolute;
    z-index: 3;
    width: auto;
    height: 568px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  .rectangle--video_phone{
    width: 253px;
    background: #36506c;
    height: 98%;
    top: 4px;
    overflow: hidden;
    z-index: -2;
    position: relative;
    margin: 0 auto;
  }
  .mockup--phone{
    height: 550px;
  }
  video{
    width: 100%;
    height: auto;
    position: relative;
  }
  .wrapper--mockup_phone{
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    bottom:0;
    height: 100%;
    left: 0;
    right: 0;
    width: 71vh;
    position: relative;
    overflow: hidden;
  }

}