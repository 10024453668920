@import '../../utils/templating/logistic_flux/main';
//DESKTOP MODALE
@media screen and (min-width: 1400px) {
  #hubspot--form_modal {
    .modal-dialog {
      max-width: 1200px;
      height:94vh;
    }
    .modal-content{
      padding:0;
    }
    .main{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .hbspt-form{
      width: 74%;
      margin-left: auto;
      #message2-5f163edd-fc18-4a03-8c87-6792455e5ca2{
        min-height:100px;
      }
    }
    .img--fixed_hubspot_desktop{
      position: absolute;
      left: 0;
      top:0;
      bottom:0;
      width: 25%;
      background-attachment: inherit;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../../../public/img/general/img-modale.jpg");
    }
  }
}
.img--form{
  background-attachment: fixed;
  background-position: center;
  position: relative;
  border-radius: 9px 0 0 9px;
}
.img--fixed_hubspot_resp img--form{
  width: 100%;
   height: 360px;
    background-image: url("../../../../../public/img/general/img-form.jpg");
}
.hs-richtext {
  h1 {
    span {
      font-size: 1.25rem!important;
      font-weight: 700;
      font-family: "manrope", sans-serif;
      color: rgba(54, 80, 108, 1)!important;
    }
      .yellow--deco{
        color: $color_primary_400!important;
      }
  }
}
fieldset, .hs_error_rollup{
  width: 90%!important;
  margin: 0 auto!important;
  max-width: 900px!important;
}
.hs_error_rollup{
  color:$color_error_700;
}
input#zip-5f163edd-fc18-4a03-8c87-6792455e5ca2{
  width: 45%;
}
input, textarea, select{
  width: 90%;
  height:40px;
  background: #FFFFFF;
  border: 1px solid #AEC2DA;
  box-sizing: border-box;
  border-radius: 2px;
  padding:10px;
  margin:4px 0 10px 0;
  color:rgba(54,80,108,1);
  caret-color:rgba(54,80,108,1);
}
.hs_submit{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:30px 0;
}
.hs-button{
  white-space: nowrap!important;
  border:none;
  border-radius: 4px;
  background-color: $color_secondary_400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  padding:0 30px;
  font-family:"manrope",sans-serif;
  font-weight: 500;
  margin:0 auto;
  @include transition-hover_quick;
  &:hover{
    color: white;
    background-color: $color_primary_400;
    text-decoration: none;
  }
}
label.hs-error-msg{
  position: relative;
  top: -13px;
  color: $color_error_700!important;
}
.hs-form-field label{
  font-family: "roboto",sans-serif;
  color: $color_secondary_300;
}
.submitted-message{
  width: 90%;
  height: 100vh;
  text-align: center;
  margin: 40px auto;
  color: #36506c;
}
#message2-5f163edd-fc18-4a03-8c87-6792455e5ca2{
  min-height:190px;
}
@media screen and (min-width: 992px){
  .hs-richtext{
    h1:nth-child(1){
      display:none;
    }
  }
  .hs-richtext h1 {
    margin: 25px 0;
    span {
      font-size: 2.175rem !important;
    }
  }
  header .not--homepage .white--logo_a{
    display: none;
  }
  header .header--main.not--homepage .btn--main.connexion--header{
    color:white;
    background-color: $color_secondary_400;
  }
  .header--main.not--homepage .header--content .yellow--logo{
    display: flex;
  }
  .hs_submit{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px 0;
  }
}
@media screen and (min-width: 920px){
  header .header--main nav.navbar ul li .nav-link{
    color:$color_secondary_400!important;
  }
  header .header--main .header--content .white--logo{
    display: none!important;
  }
  header .header--main .header--content .yellow--logo{
    display: flex!important;
  }
}